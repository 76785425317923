import { Options, Vue } from "vue-class-component";
import Input from "@/components/input/input.vue";
import Button from "@/components/button/button.vue";
import Checkbox from "@/components/checkbox/checkbox.vue";
import ContentHeader from "@/modules/content-header/content-header.vue";

@Options({
    name: "app-system-config",
    components: {
        "app-content-header": ContentHeader,
        "app-checkbox": Checkbox,
        "app-input": Input,
        "app-button": Button,
    },
})
export default class Config extends Vue {
    private activeTab = "COMMON";

    private setActiveTab(tab: string): void {
        this.activeTab = tab;
    }
}
